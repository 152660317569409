import React from "react";
import { Player } from "video-react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

//Responsive Full-Screen
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";

import IOSTutorialDialogStep1 from "../media/01-mob-profile-downloaded-compressed.mp4";
import IOSTutorialDialogStep2 from "../media/02-mob-install-profile-compressed.mp4";
import IOSTutorialDialogStep3 from "../media/03-mob-cert-on-compressed.mp4";
import IOSTutorialDialogStep4 from "../media/2021ipad.mp4";

import IOSTutorialDialogPreviewStep4 from "../media/2021ipadpreview.png";

export default function IOSTutorialDialog(props) {
  const openVideoTutorial = props.openVideo;

  const urls = {
    step1: IOSTutorialDialogStep1,
    step2: IOSTutorialDialogStep2,
    step3: IOSTutorialDialogStep3,
    step4: IOSTutorialDialogStep4,
  };

  const previewUrls = IOSTutorialDialogPreviewStep4;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {openVideoTutorial && (
        <div>
          <Player startTime={78} poster={previewUrls.step4}>
            <source src={urls.step4} />
          </Player>
        </div>
      )}
      <Link onClick={handleClickOpen}>
        <Typography
          variant="body1"
          component="p"
          align="center"
          color="primary"
        >
          Instructions for iPhone/iPad
        </Typography>
      </Link>
      <Dialog
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography
            style={{ fontFamily: "Montserrat" }}
            variant="body1"
            component="p"
            align="center"
            color="primary"
          >
            How To Install Certificate
          </Typography>
        </DialogTitle>
        <DialogContent dividers align="center">
          <Carousel
            width="70%"
            autoPlay={true}
            interval={20000}
            showThumbs={false}
          >
            <div>
              <video
                alt="Step 1"
                style={{ width: "auto", height: "60vh", paddingBottom: "50%" }}
                src={urls.step1}
                type="video/mp4"
                autoPlay="true"
                loop="true"
              />
              <p className="legend">
                <Typography
                  style={{ fontFamily: "Montserrat" }}
                  variant="body1"
                  component="p"
                  align="center"
                >
                  Step 1.
                </Typography>
                <Typography
                  style={{ fontFamily: "Montserrat" }}
                  gutterbox="true"
                  align="center"
                >
                  Allow the download.
                </Typography>
              </p>
            </div>
            <div>
              <video
                alt="Step 2"
                style={{ width: "auto", height: "60vh", paddingBottom: "50%" }}
                src={urls.step2}
                type="video/mp4"
                autoPlay="true"
                loop="true"
              />
              <p className="legend">
                <Typography
                  style={{ fontFamily: "Montserrat" }}
                  variant="body1"
                  component="p"
                  align="center"
                >
                  Step 2.
                </Typography>
                <Typography
                  style={{ fontFamily: "Montserrat" }}
                  gutterbox="true"
                  align="center"
                >
                  Go to the Settings App and Install the downloaded profile.
                </Typography>
              </p>
            </div>
            <div>
              <video
                alt="Step 3"
                style={{ width: "auto", height: "60vh", paddingBottom: "50%" }}
                src={urls.step3}
                type="video/mp4"
                autoPlay="true"
                loop="true"
              />
              <p className="legend">
                <Typography
                  style={{ fontFamily: "Montserrat" }}
                  variant="body1"
                  component="p"
                  align="center"
                >
                  Step 3.
                </Typography>
                <Typography
                  style={{ fontFamily: "Montserrat" }}
                  gutterbox="true"
                  align="center"
                >
                  Turn on the Certificate in Settings {">"} General {">"} About{" "}
                  {">"} Certificate Trust Settings
                </Typography>
              </p>
            </div>
          </Carousel>
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
