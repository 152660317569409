import React from "react";
import MainScreen from "./Screens/MainScreen";
import MainTheme from "./Theme/MainTheme";
import "./App.css";

export default function App() {
  return (
    <div>
      <MainTheme>
        <MainScreen />
      </MainTheme>
    </div>
  );
}
