import React, { useState, useEffect } from "react";
import Welcome from "react-welcome-page";
import appLogo from "../image_store/CEM-logo-Disc-white-01.svg";
import fullLogo from "../image_store/CEM-logo.svg";
import lightFullLogo from "../image_store/CEM-logo-white.png";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import ProTip from "../components/ProTip";
import CorrespondingDownloadLink from "../components/CorrespondingDownloadLink";
import useTheme from "@mui/material/styles/useTheme";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { links } from "../links";

//Used by Video React
import "video-react/dist/video-react.css";

import { version } from "../autobuild_version";

// import WifiRoundedIcon from "@mui/icons-material/WifiRounded";
// import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import VerifiedUserRoundedIcon from "@mui/icons-material/VerifiedUserRounded";
// import WhatshotRoundedIcon from "@mui/icons-material/WhatshotRounded";

export const AppName = "CEM Certificate";
export const AppEdition = "Firewall Certificate";
export const AppVersion = version;

function Copyright() {
  return (
    <Typography
      fontSize={10}
      component="div"
      color="textSecondary"
      align="center"
    >
      {"Copyright © "}
      <Link href="https://cem.com.au/">
        <Typography
          fontSize={10}
          component="span"
          color="textSecondary"
          align="center"
        >
          Christian Education Ministries
        </Typography>
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
      <br />
      {"v." + AppVersion}
    </Typography>
  );
}

function PopUpDialog() {
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="enclosed" onClick={handleClickOpen}>
        <Typography variant="body1" component="p" align="center">
          Why do I need to install this?
        </Typography>
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="body1" component="p" align="center">
            Why do I need to install this?
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterbox="true" align="center">
            In order for you to obtain internet access, inspection and filtering
            of HTTPS traffic, your device needs to have the firewall's
            certificate installed.
          </Typography>
          <br />
          <Typography gutterbox="true" align="center">
            Firewalls essentially act as a proxy for the web server, decrypting
            the browser connection and, if needed, encrypting it again from the
            firewall to the web server.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function HelpDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const standAloneCertificateURL = links.standAloneCertificateURL;
  // const standAloneCertificateURLIntegrity =
  //   links.standAloneCertificateURLIntegrity;

  return (
    <div>
      <Button variant="enclosed" onClick={handleClickOpen}>
        <Typography variant="caption" component="p" align="center">
          Get Support
        </Typography>
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="body1" component="p" align="center">
            Support
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterbox="true" align="center">
            To download the standalone certificate{" "}
            <a href={standAloneCertificateURL}>click here.</a>
          </Typography>
          <br />
          <Typography gutterbox="true" align="center">
            Make sure to install the certificate to the "System" certificate
            keychain and set the certificate trust settings to Always Trust.
          </Typography>
          <Typography gutterbox="true" align="center">
            If you are still having issues, please contact
          </Typography>
          <Typography gutterbox="true" align="center">
            helpdesk.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function MainScreen() {
  const theme = useTheme();

  //Assume certificate is installed by default. So image tries to load, if it fails it will change certificate status to false.
  const [isCertificateInstalled, setIsCertificateInstalled] = useState(true);

  let imageURLBase = "https://picsum.photos/500/500.jpg?blur&v=";
  let successImage = imageURLBase + new Date(Date.now()).getTime();

  function onErrorLoadingImage() {
    console.log("Cert may not be installed.");
    setIsCertificateInstalled(false);
  }

  function onImageLoadedSuccess() {
    setIsCertificateInstalled(true);
  }

  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    document.title = AppName;
  }, []);

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="welcome-container">
        <Welcome
          loopDuration={1000}
          data={[
            {
              image: appLogo,
              // text: "CHRISTIAN EDUCATION MINISTRIES",
              textAnimation: "fadeIn",
              imageAnimation: "rubberBand",
              backgroundColor:
                theme.palette.mode === "dark" ? "#000" : "#2a333c",
            },
          ]}
        />
      </div>

      <Paper elevation={0}>
        <Box
          style={{ minHeight: "100vh" }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Container maxWidth="lg">
            <Box my={1} style={{ padding: "30px", minHeight: "80vh" }}>
              <Box align="center">
                <img
                  style={{
                    margin: "0.5rem",
                    display: "block",
                    maxWidth: "200px",
                    maxHeight: "80px",
                    width: "auto",
                    height: "auto",
                    filter: `drop-shadow(0px 0px 0px rgb(255 255 255 / 0.6))`,
                  }}
                  src={theme.palette.mode === "dark" ? lightFullLogo : fullLogo}
                  alt="AUSTRALIAN CHRISTIAN COLLEGE"
                />
              </Box>
              <Typography variant="h6" align="center">
                Network Certificate
              </Typography>
              <Box align="center" maxWidth="100%">
                <ProTip />
              </Box>
              <Box>
                {
                  // TODO: Remove && false for site to function normally and show Installed when installed.
                  isCertificateInstalled && false ? (
                    <Box align="center">
                      <Typography
                        p="16"
                        variant="h5"
                        component="h1"
                        align="center"
                        color="primary"
                      >
                        <VerifiedUserRoundedIcon
                          style={{
                            verticalAlign: "middle",
                            fontSize: "24px",
                          }}
                        />{" "}
                        Installed.
                      </Typography>
                      <img
                        onError={onErrorLoadingImage}
                        onLoad={onImageLoadedSuccess}
                        src={successImage}
                        style={{
                          borderRadius: "10px",
                          width: "15%",
                          height: "auto",
                        }}
                        alt={
                          "If you are seeing this text, its possible that there may be issues connecting to the internet."
                        }
                      />
                    </Box>
                  ) : (
                    <Box>
                      <CorrespondingDownloadLink />
                    </Box>
                  )
                }
                <Box align="center">
                  <Typography fontSize={8} variant="body1" align="center">
                    {time}
                  </Typography>
                </Box>
                <img
                  onError={onErrorLoadingImage}
                  onLoad={onImageLoadedSuccess}
                  src={successImage}
                  style={{
                    display: "none",
                    borderRadius: "10px",
                    width: "15%",
                    height: "auto",
                  }}
                  alt={
                    "If you are seeing this text, its possible that there may be issues connecting to the internet."
                  }
                />
              </Box>
              <Box align="center">
                <PopUpDialog />
              </Box>
              <Box align="center">
                <HelpDialog />
              </Box>
              <Box padding="8px">
                <Copyright />
              </Box>
            </Box>
          </Container>
        </Box>
      </Paper>
    </>
  );
}

export default MainScreen;
