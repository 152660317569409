/**
 * Mui Theme for the main application
 * using material-ui
 * modern theme
 * createTheme

 */
import { useMemo } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function MainTheme(props) {
  let prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const cemTheme = useMemo(
    () =>
      createTheme({
        companyName: "CEM",
        logo: {
          verticalInverted: !prefersDarkMode
            ? "CEM-logo-Disc-01.svg"
            : "CEM-logo-Disc-white-01.svg",
          vertical: prefersDarkMode
            ? "CEM-logo-Disc-white-01.svg"
            : "CEM-logo-Disc-white-01.svg",
        },
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          primary: {
            darkest: "#0c0f12",
            darker: "#111519",
            dark: "#161b20",
            main: "#181E24",
            light: "#5d6266",
            lighter: "#74787c",
            lightest: "#a3a5a7",
            contrastText: "#e0f2ff",
          },
          secondary: {
            darkest: "#acacac",
            darker: "#c4c4c4",
            dark: "#dddddd",
            main: "#F5F5F5",
            light: "#f8f8f8",
            lighter: "#fafafa",
            lightest: "#FFFFFF",
            contrastText: "#000",
          },
          error: {
            main: "#C91D1D",
            contrastText: "#ffffff",
          },
          warning: {
            main: "#E8A530",
            contrastText: "#ffffff",
          },
          info: {
            main: "#3189E8",
            contrastText: "#ffffff",
          },
          success: {
            main: "#5EB234",
            contrastText: "#ffffff",
          },
        },
        typography: {
          fontFamily:
            '"proxima-nova", "Montserrat", "Helvetica", "Arial", sans-serif',
          fontSize: 14,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
      }),
    [prefersDarkMode]
  );

  return <ThemeProvider theme={cemTheme}>{props.children}</ThemeProvider>;
}
