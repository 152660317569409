import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import AppleIcon from "@mui/icons-material/Apple";
import LaptopWindowsIcon from "@mui/icons-material/LaptopWindows";
import AndroidIcon from "@mui/icons-material/Android";

import CertificateIcon from "../image_store/web-shield.png";
// import CertificateIcon from '../image_store/contract.png';
// import CertificateIcon from '../image_store/security-ssl.png';
import CertificateImage from "../image_store/web-application-firewall.png";

import MacTutorialDialog from "./MacTutorialDialog";
import IOSTutorialDialog from "./IOSTutorialDialog";
import WindowsTutorialDialog from "./WindowsTutorialDialog";

// import HologramImage from "../image_store/holo.png";

import { links } from "../links";

import {
  // BrowserView,
  // MobileView,
  // AndroidView,
  // IEView,
  // IOSView,
  // TabletView,
  // WinPhoneView,
  // MobileOnlyView,
  // SmartTVView,
  // ConsoleView,
  // WearableView,
  // CustomView,
  // CustomViewProps,
  // ViewProps,
  // isBrowser,
  isDesktop,
  isMobile,
  isTablet,
  // isSmartTV,
  // isConsole,
  // isWearable,
  isMobileSafari,
  // isChromium,
  // isMobileOnly,
  isAndroid,
  isWinPhone,
  isIOS,
  isChrome,
  // isFirefox,
  isSafari,
  // isOpera,
  // isIE,
  // isEdge,
  // isYandex,
  osVersion,
  osName,
  // fullBrowserVersion,
  // browserVersion,
  browserName,
  // mobileVendor,
  // mobileModel,
  // engineName,
  // engineVersion,
  // getUA,
  // deviceType,
  // deviceDetect,
  // isIOS13,
  // isIPad13,
  // isIPhone13,
  // isIPod13,
  // isElectron,
  // isEdgeChromium,
  // isLegacyEdge,
  isWindows,
  isMacOs,
  // withOrientationChange,
  // isMIUI,
  // isSamsungBrowser,
  // OsTypes,
  // BrowserTypes,
} from "react-device-detect";

const InstallButton = function (props) {
  return (
    <Button {...props} size="medium" variant="contained" color="info">
      Install Certificate
    </Button>
  );
};

const minimumRequiredVersions = {
  mac: /10.[1-9][5-9](.[0-9]([0-9]?))?|11(.[0-9]([0-9])?)?(.[0-9]([0-9])?)?/g,
  win: "7",
  ios: /[1-9][3-9](.[0-9][0-9])?(.[0-9][0-9])?/g,
  android: /1[0-9](.[0-9]([0-9]?))?(.[0-9]([0-9]?))?/g,
};

const CorrespondingDownloadLink = function (props) {
  const iOSCertDownloadURL = links.iOSCertDownloadURL;
  const macCertDownloadURL = links.macCertDownloadURL;
  // const macCertDownloadURLIntegrity = links.macCertDownloadURLIntegrity;
  // TODO: Change the url windows to be the installer exe once its produced.
  const windowsCertDownloadURL = links.windowsCertDownloadURL;
  // const windowsCertDownloadURLIntegrity = links.windowsCertDownloadURLIntegrity;
  const androidCertDownloadURL = links.androidCertDownloadURL;
  // const androidCertDownloadURLIntegrity = links.androidCertDownloadURLIntegrity;
  const standAloneCertificateURL = links.standAloneCertificateURL;
  // const standAloneCertificateURLIntegrity =
  //   links.standAloneCertificateURLIntegrity;

  let certificateIcon = (
    <img
      alt="Cert Icon"
      src={CertificateIcon}
      style={{ maxWidth: "none", width: "50px", height: "auto" }}
    />
  );
  let certificateImage = (
    <img
      alt="Cert Icon"
      src={CertificateImage}
      style={{ maxWidth: "none", width: "70px", height: "auto" }}
    />
  );

  let osType,
    osVersionDisplay,
    deviceType,
    browserType,
    certificateInstallButton,
    deviceIcon;
  let isDeviceOSObsolete = true;
  let isDeviceCompatible = false;

  osType = osName;
  osVersionDisplay = osVersion;
  browserType = browserName;

  // console.log("Is mobile Safari " + isMobileSafari);
  // console.log("Is Safari " + isSafari);

  const [showMacTutorialDialog, setShowMacTutorialDialog] = useState(false);
  const [showIOSTutorialDialog, setShowIOSTutorialDialog] = useState(false);
  const [showWindowsTutorialDialog, setShowWindowsTutorialDialog] =
    useState(false);

  const [openVideo, setOpenVideo] = useState(false);

  //Check on First Load:
  useEffect(function () {
    if (isMacOs) {
      setShowMacTutorialDialog(true);
      return;
    }
    if (isIOS) {
      setShowMacTutorialDialog(false);
      setShowIOSTutorialDialog(true);
      return;
    }
    if (isWindows) {
      setShowWindowsTutorialDialog(true);
      setShowMacTutorialDialog(false);
      setShowIOSTutorialDialog(false);
      return;
    }
  }, []);

  //Check Continually on Rerenders.

  /**
   *  OSes
   */

  if (isMacOs) {
    if (!isSafari && !isChrome) {
      return (
        <Box align="center">
          <Typography color="error" variant="h6" component="h2">
            You are using {browserType}, please use Safari or Chrome instead.{" "}
            {isSafari}
          </Typography>
        </Box>
      );
    }
    if (osVersion.match(minimumRequiredVersions.mac)) {
      isDeviceOSObsolete = false;
    }
    isDeviceCompatible = true;
    deviceIcon = <AppleIcon fontSize="large" />;
    certificateInstallButton = (
      <InstallButton
        onClick={() => {
          window.location.assign(macCertDownloadURL);
          setShowMacTutorialDialog(true);
          setOpenVideo(true);
        }}
      />
    );
  }

  if (isIOS) {
    if (!isMobileSafari || !isSafari) {
      return (
        <Box align="center">
          <Typography color="error" variant="h6" component="h2">
            You are using {browserType}, please use Safari instead. {isSafari}
          </Typography>
        </Box>
      );
    }
    if (osVersion.match(minimumRequiredVersions.ios)) {
      isDeviceOSObsolete = false;
    }
    deviceIcon = <AppleIcon fontSize="large" />;
    isDeviceCompatible = true;
    certificateInstallButton = (
      <InstallButton
        onClick={() => {
          window.location.assign(iOSCertDownloadURL);
          setShowIOSTutorialDialog(true);
          setOpenVideo(true);
        }}
      />
    );
  }

  if (isWindows) {
    if (osVersion.match(minimumRequiredVersions.win)) {
      isDeviceOSObsolete = false;
    }
    deviceIcon = <LaptopWindowsIcon fontSize="large" />;
    isDeviceCompatible = true;
    certificateInstallButton = (
      <InstallButton
        onClick={() => {
          window.location.assign(windowsCertDownloadURL);
          setOpenVideo(true);
        }}
      />
    );
  }

  if (isAndroid) {
    if (osVersion.match(minimumRequiredVersions.android)) {
      isDeviceOSObsolete = false;
    }
    deviceIcon = <AndroidIcon fontSize="large" />;
    isDeviceCompatible = false;
    certificateInstallButton = (
      <InstallButton
        onClick={() => {
          window.location.assign(androidCertDownloadURL);
          setOpenVideo(true);
        }}
      />
    );
  }

  if (isWinPhone) {
    isDeviceCompatible = false;
  }

  /**
   * Devices
   */

  if (isDesktop) {
    deviceType = "Desktop";
  }

  if (isMobile) {
    deviceType = "Mobile";
  }

  if (isTablet) {
    deviceType = "Tablet";
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        alignItems="center"
        direction="column"
        justify="space-evenly"
      >
        <Grid item xs>
          <Paper elevation={15}>
            <Card>
              <CardHeader
                avatar={deviceIcon}
                title={osType + "  Certificate"}
                subheader={deviceType + " " + browserType}
                action={<Box style={{ padding: "5px" }}>{certificateIcon}</Box>}
              />
              <CardContent align="center">
                <Typography
                  style={{ fontFamily: "Courier" }}
                  variant="caption"
                  align="center"
                  color="textSecondary"
                  component="div"
                >
                  YOUR WIFI CERTIFICATE
                </Typography>
                {certificateImage}
                {isDeviceOSObsolete ? (
                  <Typography color="error" component="p">
                    You may need to update your device.
                  </Typography>
                ) : (
                  <Typography color="body2" component="p">
                    Your browser indicates OS version: {osVersionDisplay}
                  </Typography>
                )}
                {isDeviceCompatible ? (
                  <Typography color="textSecondary" variant="h6" component="h2">
                    Your device is compatible.
                  </Typography>
                ) : (
                  <>
                    <Typography color="error" variant="h6" component="h2">
                      Your device may not be compatible.
                    </Typography>
                    <Typography
                      gutterbox="true"
                      align="center"
                      color="secondary"
                    >
                      <a href={standAloneCertificateURL}>
                        Download Certificate
                      </a>
                    </Typography>
                  </>
                )}
                <Box align="center" margin="2%">
                  {certificateInstallButton}
                </Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  align="center"
                  px={2}
                >
                  Click install certificate to download the ACC Network
                  Certificate onto your device and follow the prompts to
                  continue.
                </Typography>
                <br />
                {showMacTutorialDialog ? (
                  <MacTutorialDialog openVideo={openVideo} />
                ) : null}
                {showIOSTutorialDialog ? (
                  <IOSTutorialDialog openVideo={openVideo} />
                ) : null}
                {showWindowsTutorialDialog ? (
                  <WindowsTutorialDialog openVideo={openVideo} />
                ) : null}
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default CorrespondingDownloadLink;
