import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

import ErrorOutlineIcon from "@mui/icons-material/NotificationImportant";

function LightBulbIcon(props) {
  return <ErrorOutlineIcon {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0, 1),
    fontFamily: "Montserrat Semibold",
  },
  lightBulb: {
    verticalAlign: "middle",
    marginRight: theme.spacing(0.5),
    fontSize: "12px",
  },
}));

export default function ProTip() {
  const classes = useStyles();
  return (
    <Typography
      className={classes.root}
      variant="caption"
      component="p"
      color="secondary.darkest"
    >
      <LightBulbIcon color="secondary.darker" className={classes.lightBulb} />
      Download and install your certificate to get internet access.
    </Typography>
  );
}
