import React from "react";
import { Player } from "video-react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

//Responsive Full-Screen
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";

import WindowsTutorialDialogStep1 from "../media/2021windows.mp4";

export default function WindowsTutorialDialog(props) {
  const openVideoTutorial = props.openVideo;

  const urls = {
    step1: WindowsTutorialDialogStep1,
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {openVideoTutorial && (
        <div>
          <Player autoPlay muted startTime={78}>
            <source src={urls.step1} />
          </Player>
        </div>
      )}
      <Link onClick={handleClickOpen}>
        <Typography
          style={{ fontFamily: "Montserrat" }}
          variant="body1"
          component="p"
          align="center"
          color="primary"
        >
          Instructions for Windows
        </Typography>
      </Link>
      <Dialog
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography
            style={{ fontFamily: "Montserrat" }}
            variant="body1"
            component="p"
            align="center"
            color="primary"
          >
            How To Install Certificate
          </Typography>
        </DialogTitle>
        <DialogContent dividers align="center">
          <Carousel autoPlay={true} interval={20000} showThumbs={false}>
            <div>
              <video
                alt="Step 1"
                style={{ width: "auto", height: "auto", paddingBottom: "50%" }}
                src={urls.step1}
                type="video/mp4"
                autoPlay="true"
                loop="true"
              />
              <p className="legend">
                <Typography
                  style={{ fontFamily: "Montserrat" }}
                  variant="body1"
                  component="p"
                  align="center"
                >
                  Important
                </Typography>
                <Typography
                  style={{ fontFamily: "Montserrat" }}
                  gutterbox="true"
                  align="center"
                >
                  When opening the installer application, when you see the
                  'Windows Protected Your PC' prompt click on "More Info" and
                  "Run Anyway"
                </Typography>
              </p>
            </div>
          </Carousel>
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
