export const links = {
  iOSCertDownloadURL:
    process.env.PUBLIC_URL + `/cert.cer#` + new Date().getTime(),
  iOSCertDownloadURLIntegrity: `48w7reuygsfahiger8y7fhiuwrefsadghiads`,
  macCertDownloadURL:
    process.env.PUBLIC_URL + `/mac-certificate.pkg#` + new Date().getTime(),
  macCertDownloadURLIntegrity: `48w7reuygsfahiger8y7fhiuwrefsadghiads`,
  windowsCertDownloadURL:
    process.env.PUBLIC_URL + `/win-certificate.exe#` + new Date().getTime(),
  windowsCertDownloadURLIntegrity: `48w7reuygsfahiger8y7fhiuwrefsadghiads`,
  androidCertDownloadURL:
    process.env.PUBLIC_URL + `/cert.cer#` + new Date().getTime(),
  androidCertDownloadURLIntegrity: `48w7reuygsfahiger8y7fhiuwrefsadghiads`,
  standAloneCertificateURL:
    process.env.PUBLIC_URL + `/cert.cer#` + new Date().getTime(),
  standAloneCertificateURLIntegrity: `48w7reuygsfahiger8y7fhiuwrefsadghiads`,

  WindowsTutorialDialog: {
    step1: `./media/2021windows.mp4`,
  },
  IOSTutorialDialog: {
    step1: `./media/01-mob-profile-downloaded-compressed.mp4`,
    step2: `./media/02-mob-install-profile-compressed.mp4`,
    step3: `./media/03-mob-cert-on-compressed.mp4`,
    step4: `./media/2021ipad.mp4`,
  },
  IOSTutorialDialogPreview: {
    step4: `./media/2021ipadpreview.png`,
  },
  MacTutorialDialog: {
    step1: `./media/01-open-in-finder-cut-compressed.mp4`,
    step2: `./media/02-secondary-click-open-cut-compressed.mp4`,
    step3: `./media/03-install-certificate-cut-compressed.mp4`,
    step4: `./media/2021macbook.mp4`,
  },
  MacTutorialDialogPreview: {
    step4: `./media/2021macbookpreview.png`,
  },
};
